/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PRODUCT_TYPE from 'Component/Product/Product.config';
import { BUNDLE_TYPE } from 'Component/ProductBundleOption/ProductBundleOption.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductContainer as SourceProductContainer
} from 'SourceComponent/Product/Product.container';
import { ShippingMethodType } from 'Type/Checkout.type';
import { TotalsType } from 'Type/MiniCart.type';
import { ProductType } from 'Type/ProductList.type';
import {
    getGroupedProductsInStockQuantity,
    getMaxQuantity,
    getMinQuantity
} from 'Util/Product/Extract';
import { getYotpoProductReviewStates } from 'Util/Yotpo';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const YotpoDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Yotpo/Yotpo.dispatcher'
);

/** @namespace PeggysagePwa/Component/Product/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    cheapestShippingMethod: state.ConfigReducer.cheapestShippingMethod,
    cartTotals: state.CartReducer.cartTotals,
    activeOverlay: state.OverlayReducer.activeOverlay,
    currentPageProduct: state.ProductReducer?.product,
    isYotpoEnabled: state.ConfigReducer.yotpo_enabled,
    yotpoAppkey: state.ConfigReducer.yotpo_appkey
});

/** @namespace PeggysagePwa/Component/Product/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    changeItemQty: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.changeItemQty(dispatch, options)
    ),
    updateYotpo: (data) => YotpoDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateYotpo(data, dispatch)
    )
});

/** @namespace PeggysagePwa/Component/Product/Container */
export class ProductContainer extends SourceProductContainer {
    static propTypes = {
        ...SourceProductContainer.propTypes,
        cheapestShippingMethod: ShippingMethodType,
        cartTotals: TotalsType.isRequired,
        activeOverlay: PropTypes.string.isRequired,
        currentPageProduct: ProductType,
        isYotpoEnabled: PropTypes.bool.isRequired,
        yotpoAppkey: PropTypes.string.isRequired,
        changeItemQty: PropTypes.func.isRequired,
        updateYotpo: PropTypes.func.isRequired,
        isProductPage: PropTypes.bool
    };

    static defaultProps = {
        ...SourceProductContainer.defaultProps,
        cheapestShippingMethod: {},
        currentPageProduct: {},
        isProductPage: false
    };

    containerFunctions = {
        ...this.containerFunctions,
        setQuantityFromControls: this.setQuantityFromControls.bind(this)
    };

    state = {
        ...this.state,
        isInCart: false,
        isLoadingChangeQty: false,
        yotpo: {
            rating_summary: 0,
            review_count: 0
        }
    };

    /**
     * Override (copy): needed to call the static function in this overriding class
     * @param props
     * @param state
     * @returns {null|{quantity: *}}
     */
    static getDerivedStateFromProps(props, state) {
        const { quantity: quantityState } = state;
        const quantity = ProductContainer.getDefaultQuantity(props, state);

        if (quantity && typeof quantityState !== 'object') {
            return { quantity };
        }

        return null;
    }

    /**
     * Override: get quantity from cart
     * @param props
     * @param state
     * @returns {null|*}
     */
    // eslint-disable-next-line react/sort-comp
    static getDefaultQuantity(props, state) {
        const { quantity, selectedProduct } = state;
        const { product, product: { type_id: typeId, sku } = {}, cartTotals: { items: cartItems } = {} } = props;

        if (!product) {
            return null;
        }

        // Return quantity set in cart
        const productInCart = cartItems && cartItems.find((item) => item.sku === sku && !item.isFreeproduct);
        if (productInCart) {
            return productInCart.quantity;
        }

        if (typeId === PRODUCT_TYPE.grouped) {
            return getGroupedProductsInStockQuantity(product);
        }

        const minQty = getMinQuantity(selectedProduct || product);

        if (quantity < minQty) {
            return minQty;
        }

        const maxQty = getMaxQuantity(selectedProduct || product);

        if (quantity > maxQty) {
            return maxQty;
        }

        return null;
    }

    /**
     * Override
     * @returns {{selectedOptions: *}}
     */
    containerProps() {
        const {
            selectedOptions,
            isInCart,
            isLoadingChangeQty,
            yotpo
        } = this.state;
        const {
            cheapestShippingMethod,
            activeOverlay,
            isProductPage,
            isYotpoEnabled
        } = this.props;

        return {
            ...super.containerProps(),
            selectedOptions,
            isInCart,
            isLoadingChangeQty,
            cheapestShippingMethod,
            activeOverlay,
            isProductPage,
            isYotpoEnabled,
            yotpo,
            minQuantity: 0 // so we can delete cart item completely
        };
    }

    /**
     * Override: update quantity depending on cart items
     */
    componentDidMount() {
        // this.updateSelectedValues(); // called in updateQuantity()
        this.updateQuantity();
        this.updateAdjustedPrice();
        this.getYotpoReviewStates();
    }

    /**
     * Override: update qty on cart update
     * @param prevProps
     * @param prevState
     */
    componentDidUpdate(prevProps, prevState) {
        const {
            enteredOptions,
            selectedOptions,
            downloadableLinks
        } = this.state;
        const {
            enteredOptions: prevEnteredOptions,
            selectedOptions: prevSelectedOptions,
            downloadableLinks: prevDownloadableLinks
        } = prevState;

        if (
            enteredOptions !== prevEnteredOptions
            || selectedOptions !== prevSelectedOptions
            || downloadableLinks !== prevDownloadableLinks
        ) {
            this.updateAdjustedPrice();
        }

        const {
            product,
            product: { id },
            cartTotals: { items: cartItems }
        } = this.props;
        const {
            product: prevProduct,
            product: { id: prevId },
            cartTotals: { items: prevCartItems }
        } = prevProps;

        if (id !== prevId) {
            this.getYotpoReviewStates();
        }

        if (product !== prevProduct || cartItems !== prevCartItems) {
            this.updateQuantity();
        }
    }

    updateQuantity() {
        const {
            product: {
                id: productId,
                sku,
                type_id,
                items
            } = {},
            cartTotals: { items: cartItems } = {},
            currentPageProduct: { id: currentPageProductId = 0 } = {}
        } = this.props;

        // eslint-disable-next-line fp/no-let
        let productInCart = false;

        const isConfigurableProductBundle = type_id === PRODUCT_TYPE.bundle && items && items.filter(
            (group) => [BUNDLE_TYPE.kit, BUNDLE_TYPE.couleur, BUNDLE_TYPE.produit].includes(group.type)
        ).length;

        // Do not show qty changer for bundle products
        if (!isConfigurableProductBundle) {
            productInCart = cartItems.find((item) => item.sku === sku && !item.isFreeproduct);

            this.setState({ isInCart: !!productInCart });
        }

        const quantity = productInCart ? productInCart.quantity
            : ProductContainer.getDefaultQuantity(this.props, this.state);

        if (quantity) {
            this.setQuantity(quantity);
        }

        // Called only on current product in product page (prevent bug when a kit is called in the search overlay)
        if (productId === currentPageProductId) {
            this.updateSelectedValues();
        }
    }

    setQuantityFromControls(quantity) {
        const { quantity: oldQuantity = {} } = this.state;

        const oldQuantitySave = JSON.parse(JSON.stringify(oldQuantity));

        // Update the input value
        this.setQuantity(quantity);

        // Change qty in cart
        this.setState({ isLoadingChangeQty: true }, () => {
            const {
                changeItemQty,
                cartId,
                product: { sku },
                cartTotals: { items: cartItems } = {}
            } = this.props;

            const cartItem = cartItems.find((item) => item.sku === sku && !item.isFreeproduct);
            if (!cartItem) {
                this.setState({ isLoadingChangeQty: false });

                return;
            }

            const { id, quantity: itemQuantity = 1 } = cartItem;
            if (quantity === itemQuantity) {
                this.setState({ isLoadingChangeQty: false });

                return;
            }

            changeItemQty({
                uid: btoa(id),
                quantity,
                cartId,
                item: cartItem,
                oldQuantity: oldQuantitySave
            }).then(
                /** @namespace PeggysagePwa/Component/Product/Container/ProductContainer/setQuantityFromControls/setState/catch/then/finally/changeItemQty/then */
                () => {},
                /** @namespace PeggysagePwa/Component/Product/Container/ProductContainer/setQuantityFromControls/setState/catch/then/finally/changeItemQty/then/catch */
                () => {
                    // Set quantity back if fail
                    this.setQuantity(oldQuantitySave);
                }
            ).finally(
                /** @namespace PeggysagePwa/Component/Product/Container/ProductContainer/setQuantityFromControls/setState/catch/then/finally */
                () => this.setState({ isLoadingChangeQty: false })
            );
        });
    }

    /**
     * Override: set default value for configurable_options to prevent bug when adding featured product to cart
     * @returns {boolean}
     */
    validateConfigurableProduct() {
        const {
            parameters
        } = this.state;

        const { product: { configurable_options = {} } } = this.props;
        const unselectedOptions = Object.keys(configurable_options).reduce((accumulator, value) => {
            if (!parameters[value]) {
                accumulator.push(value);
            }

            return accumulator;
        }, []);

        this.setState({ unselectedOptions });

        return unselectedOptions.length > 0;
    }

    /**
     * Override: add param for cart popup
     * @returns {*}
     */
    async addToCart() {
        this.updateSelectedValues();
        const { showError, isProductPage } = this.props;

        if (this.hasError()) {
            return;
        }

        const { addProductToCart, cartId } = this.props;
        const products = this.getMagentoProduct();

        await addProductToCart({ products, cartId, openPopup: isProductPage })
            .catch(
                /** @namespace PeggysagePwa/Component/Product/Container/ProductContainer/addToCart/addProductToCart/catch */
                (error) => {
                    if (error) {
                        showError(error);
                    }
                }
            );
    }

    /**
     * Override: force selected option from param without checking the form (for handling selection from Product Popup)
     * @param data
     */
    updateSelectedValues(data = {}) {
        const { uid, forceSelectedOption } = data;

        if (uid && forceSelectedOption) {
            const selectedOptions = [uid];
            this.setState({ selectedOptions });
            return;
        }

        super.updateSelectedValues(data);
    }

    getYotpoReviewStates() {
        const {
            isYotpoEnabled, yotpoAppkey, product: { id: productId }, updateYotpo
        } = this.props;

        if (!isYotpoEnabled || !yotpoAppkey || !productId) {
            updateYotpo({
                isYotpoEnabled
            });

            return;
        }

        getYotpoProductReviewStates(productId, yotpoAppkey).then(
            /** @namespace PeggysagePwa/Component/Product/Container/ProductContainer/getYotpoReviewStates/getYotpoProductReviewStates/then */
            (res) => {
                if (res) {
                    const {
                        total_review,
                        average_score
                    } = res;

                    const yotpo = {
                        // eslint-disable-next-line no-magic-numbers
                        rating_summary: average_score * 20,
                        review_count: total_review
                    };

                    updateYotpo({
                        isYotpoEnabled,
                        review_summary: yotpo
                    });

                    this.setState({ yotpo });
                }
            }
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
