import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import { PRODUCT_BUNDLE_OPTIONS_POPUP_ID } from 'Component/Product/Product.config';
import { BUNDLE_TYPE } from 'Component/ProductBundleOption/ProductBundleOption.config';
import { PRODUCT_POPUP_ID } from 'Component/ProductPopup/ProductPopup.config';
import { showPopup } from 'Store/Popup/Popup.action';
import { ItemOptionProductType, ProductItemType } from 'Type/ProductList.type';
import { getPrice, getProductInStock } from 'Util/Product/Extract';
import { getYotpoProductReviewStates } from 'Util/Yotpo';

import ProductBundleOptionDetails from './ProductBundleOptionDetails.component';

/** @namespace PeggysagePwa/Component/ProductBundleOptionDetails/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isYotpoEnabled: state.ConfigReducer.yotpo_enabled,
    yotpoAppkey: state.ConfigReducer.yotpo_appkey
});

/** @namespace PeggysagePwa/Component/ProductBundleOptionDetails/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (popupId, payload) => dispatch(showPopup(popupId, payload))
});

/** @namespace PeggysagePwa/Component/ProductBundleOptionDetails/Container */
export class ProductBundleOptionDetailsContainer extends PureComponent {
    static propTypes = {
        option: ItemOptionProductType.isRequired,
        bundleType: PropTypes.string,
        hasDynamicPrice: PropTypes.bool,
        isSelected: PropTypes.bool,
        showPopup: PropTypes.func.isRequired,
        hideActivePopup: PropTypes.func.isRequired,
        parentOption: ProductItemType.isRequired, // info to get back to product options popup from product popup
        filteredValues: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.number)), // to retrieve filter after back from product popup
        isYotpoEnabled: PropTypes.bool.isRequired,
        yotpoAppkey: PropTypes.string.isRequired
    };

    static defaultProps = {
        bundleType: '',
        hasDynamicPrice: false,
        isSelected: false,
        filteredValues: {}
    };

    state = {
        yotpo: {
            rating_summary: 0,
            review_count: 0
        }
    };

    containerFunctions = {
        showProductPopup: this.showProductPopup.bind(this),
        isFreeProduct: this.isFreeProduct.bind(this)
    };

    inputLabelRef = createRef();

    componentDidMount() {
        this.getYotpoReviewStates();
    }

    getYotpoReviewStates() {
        const { isYotpoEnabled, yotpoAppkey, option: { product: { id: productId } } } = this.props;

        if (!isYotpoEnabled || !yotpoAppkey || !productId) {
            return;
        }

        getYotpoProductReviewStates(productId, yotpoAppkey).then(
            /** @namespace PeggysagePwa/Component/ProductBundleOptionDetails/Container/ProductBundleOptionDetailsContainer/getYotpoReviewStates/getYotpoProductReviewStates/then */
            (res) => {
                if (res) {
                    const {
                        total_review,
                        average_score
                    } = res;

                    const yotpo = {
                        // eslint-disable-next-line no-magic-numbers
                        rating_summary: average_score * 20,
                        review_count: total_review
                    };

                    this.setState({ yotpo });
                }
            }
        );
    }

    containerProps() {
        const {
            option, bundleType, hasDynamicPrice, isSelected, isYotpoEnabled
        } = this.props;
        const { yotpo } = this.state;

        return {
            option,
            bundleType,
            hasDynamicPrice,
            isSelected,
            isYotpoEnabled,
            isSelectable: this.isSelectable(),
            inputLabelRef: this.inputLabelRef,
            yotpo
        };
    }

    showProductPopup(e) {
        const {
            showPopup,
            option: {
                product: {
                    id,
                    sku
                },
                uid
            }
        } = this.props;

        e.preventDefault();

        return showPopup(
            PRODUCT_POPUP_ID,
            {
                id,
                sku,
                bundleOptionUid: uid,
                isBundleOptionSelectable: this.isSelectable(),
                onBundleOptionSelected: this.onBundleOptionSelected.bind(this),
                onBackButtonClick: this.onProductPopupBackButtonClick.bind(this)
            }
        );
    }

    /**
     * Call BackButton callback function with selected option uid as parameter,
     * so the component in previous popup can trigger the input to select it.
     * @param optionUid
     */
    onBundleOptionSelected(optionUid) {
        this.onProductPopupBackButtonClick(optionUid);
    }

    /**
     * Open previous popup (list of bundle options) when click on back button
     * @param optionUid
     */
    onProductPopupBackButtonClick(optionUid = null) {
        const {
            showPopup,
            parentOption: {
                title,
                option_id: currentOptionId
            },
            filteredValues
        } = this.props;

        showPopup(
            PRODUCT_BUNDLE_OPTIONS_POPUP_ID,
            {
                title,
                currentOptionId,
                selectedOptionUid: optionUid,
                filteredValues
            }
        );
    }

    isFreeProduct(product) {
        const {
            price_range: priceRange,
            type_id: type
        } = product;

        if (!priceRange) {
            return false;
        }

        const productPrice = getPrice(priceRange, false, {}, type);

        const {
            originalPrice: {
                maxFinalPrice: {
                    value: maxFinalPrice = 0
                } = {}
            } = {}
        } = productPrice;

        return maxFinalPrice === 0;
    }

    isSelectable() {
        const {
            option: {
                product
            },
            bundleType
        } = this.props;

        const stock = getProductInStock(product);

        return bundleType !== BUNDLE_TYPE.obligatoire && stock;
    }

    render() {
        return (
            <ProductBundleOptionDetails
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductBundleOptionDetailsContainer);
