import {
    ConfigQuery as SourceConfigQuery
} from 'SourceQuery/Config.query';
import { Field } from 'Util/Query';

/** @namespace PeggysagePwa/Query/Config/Query */
export class ConfigQuery extends SourceConfigQuery {
    _getStoreListFields() {
        return [
            'name',
            'is_active',
            'base_url',
            'base_link_url',
            'code',
            'website_code',
            'website_name',
            'is_default_store'
        ];
    }

    getSocialsField() {
        return new Field('socials')
            .addFieldList([
                'type',
                'name',
                'url'
            ]);
    }

    getTrainingCategoryInfoField() {
        return new Field('trainingCategoryInfo')
            .addFieldList([
                'id',
                'url'
            ]);
    }

    getPageTutosField() {
        return new Field('tutos')
            .addFieldList([
                'url',
                'title'
            ]);
    }

    getProFieldsByCountryField() {
        return new Field('pro_fields_by_country')
            .addFieldList([
                'code',
                'label',
                'type',
                new Field('options').addFieldList([
                    'value',
                    'label'
                ]),
                'active',
                'obligatoire',
                'validation'
            ]);
    }

    _getStoreConfigFields() {
        return [
            ...super._getStoreConfigFields(),
            'phone',
            'hours',
            this.getSocialsField(),
            'vat_number',
            'api_societe_com',
            'siret_active',
            'attestation_avs_active',
            'kbis_active',
            'students_active',
            'employees_active',
            'training',
            'freeshipping_min_amount',
            'freeshipping_min_amount_pro',
            this.getTrainingCategoryInfoField(),
            this.getPageTutosField(),
            'collectinstore_available',
            this.getProFieldsByCountryField(),
            'google_api_key',
            'checkout_gift_selection_required',
            'google_api_key',
            'yotpo_enabled',
            'yotpo_base_script',
            'yotpo_appkey'
        ];
    }

    getStoreSwitcherUrl(url, store) {
        return new Field('storeSwitcherUrl')
            .addArgument('url', 'String!', url)
            .addArgument('store', 'String!', store)
            .addFieldList([
                'url',
                'store_code',
                'store_id'
            ]);
    }
}

export default new ConfigQuery();
